import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Auth } from '@aws-amplify/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { Logger } from '@meprism/app-utils'
import QRCode from 'react-qr-code'
import { Toast } from '../toast/toast'

const serviceName = `mePrismBusinessPortal`
const ENTER_OPERATION_KEY = 'Enter'

export const TotpView = () => {
  const authContext = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [secret, setSecret] = useState('')
  const [errorText, setErrorText] = useState('')
  const [otp, setOtp] = useState('')
  const [remember, setRemember] = useState(false)
  const location = useLocation()
  const from = location.state?.from || '/'

  useEffect(() => {
    const getTotpSecret = async () => {
      if (!authContext.user || loading || errorText || secret) {
        return
      }
      try {
        setLoading(true)
        const sec = await Auth.setupTOTP(authContext.user)
        setSecret(sec)
        setErrorText('')
        setLoading(false)
      } catch (error) {
        Logger.error(`Error getting TOTP: ${error}`)
        setErrorText(`${error}`)
        setLoading(false)
        // @TODO: better
      }
    }
    getTotpSecret()
  }, [authContext.user, loading, errorText, secret])

  const url = `otpauth://totp/${serviceName}:${encodeURIComponent(
    authContext.user?.getUsername() || '',
  )}?issuer=${serviceName}&secret=${encodeURIComponent(secret)}`

  const onConfirmPress = async () => {
    setLoading(true)
    try {
      await Auth.verifyTotpToken(authContext.user, otp)
      Auth.setPreferredMFA(authContext.user, 'TOTP')
      if (remember) {
        await Auth.rememberDevice()
      }
      setLoading(false)
      navigate(from, { replace: true })
    } catch (error) {
      setLoading(false)
      Logger.error(`Error verifying TOTP: ${error}`)
      switch (error?.code) {
        case 'NotAuthorizedException':
          Toast.show({
            type: 'error',
            text1: 'Your session is expired',
          })
          navigate('/login')
          break
        case 'CodeMismatchException':
          setErrorText('That code does not match')
          break
        case 'InvalidParameterException':
          setErrorText('Please enter the code to proceed')
          break
        default:
          setErrorText(`${error}`)
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ENTER_OPERATION_KEY) {
      onConfirmPress()
    }
  }

  return (
    <Stack
      sx={{ maxWidth: 'md', mx: 'auto', alignItems: 'center', mt: 8 }}
      spacing={6}>
      <Typography variant={'h2'}>
        Scan the QR below in your authenticator app
      </Typography>
      {loading ? <CircularProgress /> : secret ? <QRCode value={url} /> : <></>}
      <Typography variant={'body2'}>
        Enter the resulting passcode below to confirm your setup
      </Typography>
      {errorText && <Typography color={'error'}>{errorText}</Typography>}
      <FormControlLabel
        control={
          <Checkbox
            checked={remember}
            onChange={(e) => {
              setRemember(e.target.checked)
            }}
          />
        }
        label={'Remember this device'}
      />
      <TextField
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        label={'One time Passcode'}
        InputLabelProps={{ shrink: true }}
        onKeyDown={handleKeyDown}
      />
      <Button variant={'contained'} disabled={loading} onClick={onConfirmPress}>
        Confirm
      </Button>
    </Stack>
  )
}
