import Loader from '@meprism/shared/src/components/atoms/Loader'
import { Autocomplete, Box, Button, Stack, Switch, TextField, Typography } from '@mui/material'
import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { Toast } from '../../toast/toast'

interface Attribute {
  Name: string
  Value: string
}

interface FormData {
  email: string
  group: string | null
  enabled: boolean
}

const UserUpdateForm: React.FC = () => {
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: {
      email: '',
      group: null,
      enabled: false,
    },
  })
  const location = useLocation()
  const navigate = useNavigate()
  const { username } = location.state || {}
  const [cognitoGroups, setCognitoGroups] = useState<string[]>([])
  const [loading, setLoading] = useState({
    groups: true,
    user: true,
    submitting: false,
  })

  const email = watch('email')

  const onSubmit = async (data: FormData) => {
    setLoading((prev) => ({ ...prev, submitting: true }))
    try {
      await API.put('CorpOnboarding', `/cognito_user/${username}`, {
        body: {
          attributes: data,
        },
      })

      Toast.show({
        type: 'success',
        text1: 'User was successfully updated.',
      })
      navigate('/users-management/manage')
    } catch (error) {
      console.error('Error updating user:', error)
      Toast.show({
        type: 'error',
        text1: 'Failed to update user. Please try again.',
      })
    } finally {
      setLoading((prev) => ({ ...prev, submitting: false }))
    }
  }

  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      Toast.show({
        type: 'error',
        text1: 'Invalid email address',
      })
    }
  }

  useEffect(() => {
    if (!username) {
      navigate('/users-management/manage')
      return
    }

    const fetchCognitoGroups = async () => {
      try {
        const response = await API.get('CorpOnboarding', '/cognito_groups', {})
        setCognitoGroups(response)
      } catch (error) {
        console.error('Error fetching Cognito groups:', error)
        Toast.show({
          type: 'error',
          text1: 'There was an error fetching Cognito groups',
        })
      } finally {
        setLoading((prev) => ({ ...prev, groups: false }))
      }
    }

    const fetchCurrentUserAttributes = async () => {
      try {
        const response = await API.get('CorpOnboarding', `/cognito_user/${username}`, {})
        setValue('email', response.Attributes.find((a: Attribute) => a.Name === 'email')?.Value || '')
        setValue('group', response.Group || null)
        setValue('enabled', response.Enabled)
      } catch (error) {
        console.error('Error fetching user attributes:', error)
        Toast.show({
          type: 'error',
          text1: 'There was an error fetching user attributes',
        })
      } finally {
        setLoading((prev) => ({ ...prev, user: false }))
      }
    }

    fetchCurrentUserAttributes()
    fetchCognitoGroups()
  }, [navigate, username, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loader loading={loading.user}>
        <Stack spacing={5}>
          <Box>
            <Typography variant="h2" sx={{ marginBottom: 3 }}>Username</Typography>
            <TextField
              name="username"
              placeholder="Username"
              required
              fullWidth
              disabled
              value={username || ''}
            />
          </Box>

          <Box>
            <Typography variant="h2" sx={{ marginBottom: 3 }}>Email</Typography>
            <Controller
              name="email"
              control={control}
              rules={{ required: 'Email is required', pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  placeholder="Email"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onBlur={(e) => {
                    field.onBlur()
                    handleEmailBlur()
                  }}
                />
              )}
            />
          </Box>

          <Box>
            <Typography variant="h2" sx={{ marginBottom: 3 }}>Group</Typography>
            <Loader loading={loading.groups}>
              <Controller
                name="group"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={cognitoGroups}
                    value={field.value}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Group" required />
                    )}
                  />
                )}
              />
            </Loader>
          </Box>

          <Box>
            <Typography variant="h2" sx={{ marginBottom: 3 }}>Account Status</Typography>
            <Controller
              name="enabled"
              control={control}
              render={({ field }) => (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>Disable</Typography>
                  <Switch {...field} checked={field.value} />
                  <Typography>Enable</Typography>
                </Stack>
              )}
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading.submitting}
              sx={{
                background: '#426BF0',
                width: '15%',
                '&:hover': { background: '#3456c0' },
              }}
            >
              {loading.submitting ? 'Updating...' : 'Update User'}
            </Button>
          </Box>
        </Stack>
      </Loader>
    </form>
  )
}

export default UserUpdateForm
