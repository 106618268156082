import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { z } from 'zod';
import {
    TextField,
    Button,
    Grid,
    Typography,
    MenuItem,
    Box,
    Switch
} from '@mui/material';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { createEmailTemplateSchema } from '@meprism/shared/src/schemas/EmailTemplateForm';
import ReactQuill from 'react-quill';
import { createEmailTemplate, updateEmailTemplate } from '../../../redux/emailTemplateService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/storeExports';
import { DatePicker } from '@mui/x-date-pickers'
import { prepareScheduledTime } from '../../../utils/helpers';

const emailTemplateSchema = createEmailTemplateSchema();
type EmailTemplateFormValues = z.infer<typeof emailTemplateSchema>;
const filter = createFilterOptions<{ email: string; inputValue?: string, title?: string }>();
type EmailOption = {
    email: string;
    inputValue?: string,
    title?: string
};

const predefinedEmails: EmailOption[] = [
    { email: 'support@meprism.com', title: 'support@meprism.com' },
    { email: 'takeaction@meprism.com', title: 'takeaction@meprism.com' },
    { email: 'no-reply@meprism.com', title: 'no-reply@meprism.com' },
];

const toEmails: EmailOption[] = [
    { email: 'all_users', title: 'All Users' },
    { email: 'all_free_users', title: 'All Free Users' },
    { email: 'all_subscribed_users', title: 'All Subscribed Users' },
];

const EmailTemplateForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const emailTemplate = location.state?.emailTemplate || null;
    const [content, setContent] = useState('');

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        trigger,
        getValues,
        setError,
        clearErrors
    } = useForm<EmailTemplateFormValues>({
        resolver: zodResolver(emailTemplateSchema),
        defaultValues: {
            templateName: '',
            toEmail: '',
            ccEmails: [],
            bccEmails: [],
            subject: '',
            fromEmail: 'support@meprism.com',
            status: true,
            templateType: '',
            scheduledTime: "Relative Date",
            scheduledMonth: undefined,
        },
    });
    const onSubmit = async (data: EmailTemplateFormValues, sendTestEmail?: boolean) => {
        try {
            const templateData = {
                ...data,
                sendTestEmail: Boolean(sendTestEmail),
                scheduledTime: prepareScheduledTime(data.scheduledTime, data.scheduledMonth)
            }
            if (emailTemplate) {
                await dispatch(updateEmailTemplate(templateData)).unwrap();
            }
            else {
                await dispatch(createEmailTemplate(templateData)).unwrap();
            }
            if (sendTestEmail) return;
            navigate('/email-outbox/list', { replace: true });
        } catch (error) {
            console.error(error);
        }

    };

    const handleSendTestEmail = () => {
        const selectedToEmail = getValues("toEmail");

        if (selectedToEmail === "all_free_users" || selectedToEmail === "all_subscribed_users" || selectedToEmail === 'all_users') {
            setError("toEmail", {
                type: "manual",
                message: "Please enter a custom email address to send a test email.",
            });
            return;
        }

        // Clear any previous errors
        clearErrors("toEmail");
        handleSubmit((data) => onSubmit(data, true))();
    };

    useEffect(() => {
        if (emailTemplate) {
            const { type, month, day, year } = emailTemplate.scheduledTime || {};

            let formattedScheduledTime: Date | "end_of_month" | undefined = undefined;
            let formattedScheduledMonth: Date | undefined = undefined;

            if (type === "end_of_month" && month) {
                // Map month name to index
                const monthIndex = new Date(`${month} 1, ${year || new Date().getFullYear()}`).getMonth();
                const currentYear = year || new Date().getFullYear();

                // Get last day of the month as a Date object
                formattedScheduledMonth = new Date(currentYear, monthIndex + 1, 0);

                // Set scheduledTime to "end_of_month"
                formattedScheduledTime = "end_of_month";
            } else if (type === "relative_date" && day && month && year) {
                // Parse scheduledTime for relative_date
                formattedScheduledTime = dayjs(`${month} ${day} ${year}`, "MMMM D YYYY").toDate();
            }

            // Populate the fields
            const safeEmailTemplate: Partial<EmailTemplateFormValues> = {
                ...emailTemplate,
                scheduledTime: formattedScheduledTime,
                scheduledMonth: formattedScheduledMonth,
            };

            Object.keys(safeEmailTemplate).forEach((key) => {
                const typedKey = key as keyof EmailTemplateFormValues;

                // Ensure the value is valid for the schema
                const value = safeEmailTemplate[typedKey];
                if (value !== undefined && value !== null) {
                    setValue(typedKey, value);
                    trigger(typedKey);
                }
            });

            if (emailTemplate.body) {
                setContent(emailTemplate.body);
            }
        }
    }, [emailTemplate, setValue, trigger]);


    useEffect(() => {
        if (location.pathname === '/email-outbox/create') {
            location.state = null;
            setContent('');
            reset();
            setValue('templateName', '')
        }
    }, [location.pathname, reset, location, setValue]);

    return (
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Grid item xs={12} container spacing={2} alignItems={'center'} justifyContent={'center'} >
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="templateName"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <Typography variant="body2" gutterBottom>
                                    * Template Name
                                </Typography>
                                <TextField
                                    {...field}
                                    disabled={emailTemplate?.templateName}
                                    fullWidth
                                    error={!!errors.templateName}
                                    helperText={
                                        errors.templateName &&
                                        errors.templateName.message
                                    }
                                />
                            </div>
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="templateType"
                        control={control}
                        render={({ field }) => (
                            <>
                                <div>
                                    <Typography variant="body2" gutterBottom>
                                        * Type
                                    </Typography>
                                </div>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={field.value}
                                    defaultValue={emailTemplate?.templateType}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setValue('templateType', value)
                                        trigger('templateType')
                                        if (value === "monthly_stats_email") {
                                            setValue('scheduledTime', 'end_of_month')
                                            setValue('subject', 'Your mePrism Removal Progress Report')
                                        }

                                    }}
                                    error={!!errors.templateType}
                                    helperText={errors.templateType?.message}
                                >
                                    <MenuItem value="monthly_stats_email">Monthly Stats Email</MenuItem>
                                    <MenuItem value="generic_email">Generic Email</MenuItem>
                                </TextField>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="toEmail"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={
                                    toEmails.find((option) => option.email === field.value) || field.value
                                }
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        field.onChange(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        field.onChange(newValue.inputValue);
                                        trigger('toEmail');
                                    }
                                    else if (newValue) {
                                        field.onChange(newValue.email);
                                        trigger('toEmail');
                                    } else {
                                        field.onChange('');
                                        trigger('toEmail');
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    const isExisting = options.some(
                                        (option) => inputValue === option.email,
                                    );
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            email: inputValue,
                                            title: `Add "${inputValue}"`,
                                        });
                                    }
                                    return filtered;
                                }}
                                options={toEmails}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.title || option.email;
                                }}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps} value={option.email}>
                                            {option.title}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <div>
                                        <Typography variant="body2" gutterBottom>
                                            * To Email
                                        </Typography>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={!!errors.toEmail}
                                            helperText={errors.toEmail?.message}
                                            placeholder='Select an option or provide a custom email address'
                                        />
                                    </div>
                                )}
                                freeSolo
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="fromEmail"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={
                                    predefinedEmails.find((option) => option.email === field.value) || null
                                }
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        field.onChange(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        field.onChange(newValue.inputValue);
                                    } else if (newValue) {
                                        field.onChange(newValue.email);
                                    } else {
                                        field.onChange('');
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    const isExisting = options.some(
                                        (option) => inputValue === option.email,
                                    );
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            email: inputValue,
                                            title: `Add "${inputValue}"`,
                                        });
                                    }
                                    return filtered;
                                }}
                                options={predefinedEmails}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.title || option.email;
                                }}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            {option.title}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <div>
                                        <Typography variant="body2" gutterBottom>
                                            * From Email
                                        </Typography>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={!!errors.fromEmail}
                                            helperText={errors.fromEmail?.message}
                                            placeholder='Select an option or provide a custom email address'
                                        />
                                    </div>
                                )}
                                freeSolo
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="ccEmails"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Typography variant="body2" gutterBottom>
                                    Cc
                                </Typography>
                                <ReactMultiEmail
                                    getLabel={(email, index, removeEmail) => {
                                        return (
                                            <div data-tag key={index}>
                                                <div data-tag-item>{email}</div>
                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                    ×
                                                </span>
                                            </div>
                                        );
                                    }}
                                    emails={field.value || []}
                                    onChange={(emails) => setValue("ccEmails", emails)}

                                />
                            </>
                        )}
                    />
                    {errors.ccEmails && (
                        <Typography color="error">{errors.ccEmails.message}</Typography>
                    )}
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="bccEmails"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Typography variant="body2" gutterBottom>
                                    Bcc
                                </Typography>
                                <ReactMultiEmail
                                    getLabel={(email, index, removeEmail) => {
                                        return (
                                            <div data-tag key={index}>
                                                <div data-tag-item>{email}</div>
                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                    ×
                                                </span>
                                            </div>
                                        );
                                    }}
                                    emails={field.value || []}
                                    onChange={(emails) => setValue("bccEmails", emails)}
                                />
                                {errors.bccEmails && (
                                    <Typography color="error">{errors.bccEmails.message}</Typography>
                                )}
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="subject"
                        control={control}
                        render={({ field }) => (
                            <>
                                <div>
                                    <Typography variant="body2" gutterBottom>
                                        * Subject
                                    </Typography>
                                </div>
                                <TextField
                                    {...field}
                                    value={field.value}
                                    fullWidth
                                    error={!!errors.subject}
                                    helperText={errors.subject?.message}
                                />
                            </>

                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="body"
                        control={control}
                        render={({ field }) => (
                            <>
                                <div>
                                    <Typography variant="body2" gutterBottom>
                                        Body Description
                                    </Typography>
                                </div>
                                <ReactQuill
                                    {...field}
                                    value={content}
                                    onChange={(content) => {
                                        setValue("body", content)
                                        setContent(content)
                                        trigger('body')
                                    }}
                                    theme="snow"
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['bold', 'italic', 'underline'],
                                            ['link'],
                                            [{ 'align': [] }],
                                            ['image'],
                                        ],
                                    }}
                                />
                            </>
                        )}
                    />
                    {errors.body && <Typography color="error">{errors.body.message}</Typography>}
                </Grid>
                <Grid item xs={8} sx={{ marginTop: "0.5rem" }}>
                    <Controller
                        name="scheduledTime"
                        control={control}
                        render={({ field: scheduledTimeField }) => (
                            <>
                                <Typography variant="body2" gutterBottom>
                                    Schedule Time
                                </Typography>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={scheduledTimeField.value instanceof Date ? "Relative Date" : scheduledTimeField.value === "end_of_month" ? "end_of_month" : "Relative Date"} // Convert Date to string for dropdown
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        scheduledTimeField.onChange(value);
                                        if (value !== "end_of_month") {
                                            setValue("scheduledMonth", undefined); // Reset scheduledMonth if not end_of_month
                                        }
                                    }}
                                >
                                    <MenuItem value="end_of_month">End of Month</MenuItem>
                                    <MenuItem value="Relative Date">Relative Date</MenuItem>
                                </TextField>
                                {scheduledTimeField.value === "end_of_month" && (
                                    <Controller
                                        name="scheduledMonth"
                                        control={control}
                                        defaultValue={undefined}
                                        render={({ field: scheduledMonthField }) => (
                                            <TextField
                                                select
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                sx={{ marginTop: "1rem" }}
                                                label="Select Month"
                                                onChange={(e) => {
                                                    const selectedMonth = parseInt(e.target.value, 10);
                                                    const currentYear = new Date().getFullYear();
                                                    const lastDayOfMonth = new Date(
                                                        currentYear,
                                                        selectedMonth + 1,
                                                        0
                                                    ); // Last day of the selected month
                                                    scheduledMonthField.onChange(lastDayOfMonth);
                                                }}
                                                error={!!errors.scheduledMonth}
                                                helperText={errors.scheduledMonth?.message}
                                                value={
                                                    scheduledMonthField.value
                                                        ? new Date(scheduledMonthField.value).getMonth() // Extract the month index from the Date object
                                                        : ""
                                                }                                            >
                                                {Array.from({ length: 12 }, (_, index) => (
                                                    <MenuItem key={index} value={index}>
                                                        {new Date(0, index).toLocaleString("default", { month: "long" })}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                )}
                                {scheduledTimeField.value !== "end_of_month" && (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            sx={{ marginTop: "1rem" }}
                                            value={dayjs(scheduledTimeField.value)}
                                            onChange={(newValue) => {
                                                scheduledTimeField.onChange(newValue?.toDate());
                                            }}
                                            disablePast
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!errors?.scheduledTime,
                                                    helperText: errors?.scheduledTime?.message ?? " ",
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>

                                <div>
                                    <Typography variant={'body2'}>Status</Typography>
                                </div>
                                <Switch checked={field.value} onChange={(e) => setValue('status', e.target.checked)} />
                            </Box>
                        )}
                    />
                </Grid>
                <Grid item xs={8} sx={{ marginTop: '0.5rem' }} container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant={'contained'}
                            type={'submit'}
                            color={'darkPurple'}
                            sx={{
                                background: '#426BF0',
                                width: '100%',
                                '&.Mui-disabled': {
                                    background: '#426BF0',
                                    color: '#ffffff',
                                },
                            }}
                        >
                            {emailTemplate ? 'Update' : 'Save'}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={'contained'}
                            type={'button'}
                            color={'darkPurple'}
                            sx={{
                                background: '#426BF0',
                                width: '100%',
                                '&.Mui-disabled': {
                                    background: '#426BF0',
                                    color: '#ffffff',
                                },
                            }}
                            onClick={handleSendTestEmail}
                        >
                            Send test email
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </form>
    );
};

export default EmailTemplateForm;
