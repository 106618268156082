import React, { useEffect, useState } from "react"
import Loader from '@meprism/shared/src/components/atoms/Loader'
import ConfirmationModal from '@meprism/admin/src/components/Views/ConfirmationModal'
import { API } from 'aws-amplify'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton,
  Box,
  Collapse,
  Typography,
  Tooltip,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useNavigate } from "react-router-dom"
import { Toast } from "../../toast/toast"


const Users: React.FC = () => {
  const navigate = useNavigate()
  const [cognitoUsers, setCognitoUsers] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openDetail, setOpenDetail] = useState<{ [key: string]: boolean }>({})
  const [selectedUser, setSelectedUser] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleEdit = (username: string) => {
    navigate('/users-management/update', { state: { username } })
  }

  const handleDelete = async (username: string) => {
    setIsLoading(true)
    try {
      await API.del("CorpOnboarding", `/cognito_users/${username}`, {})
      Toast.show({
        type: 'success',
        text1: 'Users was successfully deleted.',
      })
      fetchCognitoUsers()
      console.log(`User ${username} deleted successfully`)
    } catch (error) {
      console.error("Error deleting user:", error)
      Toast.show({
        type: 'error',
        text1: 'Failed to delete user. Please try again.',
      })
    }
  }

  const fetchCognitoUsers = async () => {
    try {
      const response = await API.get("CorpOnboarding", "/cognito_users", {})
      setCognitoUsers(response)
      setIsLoading(false)
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Failed to fetch users. Please try again.',
      })
      setIsLoading(false)
      console.error("Error fetching Cognito users:", error)
    }
  }


  useEffect(() => {
    fetchCognitoUsers()
  }, [])

  const toggleRow = (username: string) => {
    setOpenDetail((prevState) => ({
      ...prevState,
      [username]: !prevState[username],
    }))
  }

  const openDeleteModal = (username: string) => {
    setSelectedUser(username)
    setIsModalOpen(true)
  }

  const closeDeleteModal = () => {
    setSelectedUser(null)
    setIsModalOpen(false)
  }

  const confirmDelete = () => {
    if (selectedUser) {
      handleDelete(selectedUser)
    }
    closeDeleteModal()
  }

  return (
    <>
      <ConfirmationModal
        open={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        title="Delete User"
        description={`Are you sure you want to delete user: ${selectedUser}?`}
      />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Loader loading={isLoading}>
          <Grid xs={12} md={12}>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table sx={{ maxHeight: 600, minWidth: 1200, overflow: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Groups</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cognitoUsers.map((user, index) => (
                    <>
                      <TableRow
                        key={user.Username}
                        sx={{
                          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        }}
                      >
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => toggleRow(user.Username)}
                          >
                            {openDetail[user.Username] ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>{user.Username}</TableCell>
                        <TableCell>{user.Attributes.email}</TableCell>
                        <TableCell>
                          {user.Groups.length > 0
                            ? user.Groups.join(", ")
                            : "None"}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              sx={{
                                fontSize: 23,
                                marginRight: 8,
                                color: "grey",
                                backgroundColor: "#f5f5f5",
                                borderRadius: "50%",
                                padding: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s, transform 0.2s",
                                "&:hover": {
                                  backgroundColor: "#e0e0e0",
                                  transform: "scale(1.1)"
                                }
                              }}
                              onClick={() => openDeleteModal(user.Username)}
                            />
                          </Tooltip>
                          <Tooltip title="Edit">
                            <EditIcon
                              sx={{
                                fontSize: 23,
                                color: "grey",
                                backgroundColor: "#f5f5f5",
                                borderRadius: "50%",
                                padding: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s, transform 0.2s",
                                "&:hover": {
                                  backgroundColor: "#e0e0e0",
                                  transform: "scale(1.1)"
                                }
                              }}
                              onClick={() => handleEdit(user.Username)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={openDetail[user.Username]} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography sx={{ marginLeft: 10 }} variant="subtitle1" gutterBottom component="div">
                                Details
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Email Verified</TableCell>
                                    <TableCell>Enabled</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableCell></TableCell>
                                  <TableCell>{user.Attributes.email_verified ? "True" : "False"}</TableCell>
                                  <TableCell>{user.Enabled ? "Yes" : "No"}</TableCell>
                                  <TableCell>{user.UserStatus}</TableCell>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Loader>
      </Grid>
    </>
  )
}

export default Users
