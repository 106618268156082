import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material'
import React, { useContext } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import { AuthContext } from '@meprism/admin/src/components/Authentication/AuthContext'
import {
  getPreferredMfa,
  logOutUser,
  selectAccountEmail,
  selectMuid,
  selectPreferredMfa,
  AuthenticationActions,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import {
  setPreferredMfa,
} from '@meprism/shared/src/services/AuthenticationService'
import { Auth } from 'aws-amplify'
import { Toast } from '../../toast/toast'
import IOSSwitch from '@meprism/shared/src/components/atoms/IOSSwitch'

import { LoaderFunction, useNavigate } from 'react-router-dom'
import { AuthLoader } from '../../gateways/AuthLoader'
import { store } from '../../../redux/store'

import MpTheme from '../../../config/MpTheme'

const innerBoxSx = {
  maxWidth: MpTheme.layouts.widths.sm,
  mx: 'auto',
  width: '100%',
} as const

export const accountLoader: LoaderFunction = async () => {
  // note that NOT unwrapping this is intentional, if we can't get their MFA, then we'll render the
  // screen as though it's not set
  return store.dispatch(getPreferredMfa()).unwrap()
}

// const needingVerification = [...Object.entries(data)].filter(
//   ([_key, value]) => !(value as any)?.isUpdated,
// )
// if (needingVerification.length > 0) {
//   Logger.info(
//     `Attributes need verification: ${JSON.stringify(
//       needingVerification,
//     )}`,
//   )
// } else {
//   Logger.info('All updates were processed successfully')
// }

const Account = () => {
  const accountEmail = useAppSelector(selectAccountEmail)
  const authContext = useContext(AuthContext)

  const muid = useAppSelector(selectMuid)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { draftPreferredMfa } = useAppSelector(selectPreferredMfa)
  const enableTwoFactor = draftPreferredMfa === 'SOFTWARE_TOKEN_MFA'

  const handleLogout = async () => {
    await dispatch(logOutUser()).unwrap()
    navigate('/login')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mfaType = event.target.checked ? 'SOFTWARE_TOKEN_MFA' : 'NOMFA'
    dispatch(AuthenticationActions.setDraftMfa(mfaType))
  };

  const onTwoFactorSubmit = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      authContext.setUser(currentUser)

      if (enableTwoFactor) {
        navigate('/totp');
      } else {
        await setPreferredMfa('NOMFA')
        store.dispatch(getPreferredMfa())
        Toast.show({
          type: 'success',
          text1: 'Your MFA settings have been saved',
        })
      }
    } catch (error) {
      console.error('Error saving MFA settings:', error)
      Toast.show({
        type: 'error',
        text1: 'An error occurred while saving MFA settings. Please try again.',
      })
    }
  };

  return (
    <AuthLoader>
      {/* <DeleteAccountModal
          open={deleteAccountOpen}
          setOpen={setDeleteAccountOpen}
        /> */}
      <Stack
        divider={<Divider sx={{ mt: 4, mb: 10 }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: MpTheme.layouts.widths.md,
          width: '100%',
          mx: 'auto',
          mb: 10,
        }}>
        <Box sx={innerBoxSx}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Typography variant={'h2'}>mePrism ID</Typography>
            <Button
              variant="text"
              onClick={() => {
                navigator.clipboard.writeText(muid as string)
              }}>
              Copy
            </Button>
          </Box>
          <Typography variant={'body2'}>{muid}</Typography>
        </Box>

        <Box sx={innerBoxSx}>
          <Typography variant={'h2'}>Sign In Information</Typography>
          <TextField
            value={accountEmail}
            fullWidth
            disabled
            sx={{ marginTop: '20px' }}
          />
          {/* <Button>Forgot Password?</Button> */}

          {/* <Link to={'/resetPassword'} state={{ from: location.href }}>
              <Button variant={'text'}>Forgot Password?</Button>
            </Link> */}
        </Box>
        <Box sx={innerBoxSx}>
          <Typography variant={'h2'}>Authentication</Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={enableTwoFactor}
                onChange={handleChange}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '15px',
              },
              marginTop: '20px'
            }}
            label="Two Factor Authentication"
          />
          <Typography variant={'body2'} sx={{ fontSize: 14, marginTop: '20px' }}>
            Use your mobile authenticator app to generate one-time passcodes
            code
          </Typography>
          <Button
            onClick={onTwoFactorSubmit}
            sx={{marginTop: '20px'}}
          >
            Save Changes
          </Button>
        </Box>
        <Box sx={innerBoxSx}>
          <Box sx={{ mr: 'auto' }}>
            <Button
              variant={'outlined'}
              color={'darkPurple'}
              onClick={handleLogout}>
              Sign Out
            </Button>
          </Box>
        </Box>
      </Stack>
    </AuthLoader>
  )
}

export default Account
