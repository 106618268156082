import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from './components/Authentication/AuthContext'
import Loader from '@meprism/shared/src/components/atoms/Loader'

interface ProtectedRouteProps {
  allowedGroups: string[]
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedGroups }) => {
  const context = useContext(AuthContext)
  const { groups, loading } = context

  if (Boolean(loading)) {
    return <Loader loading={loading} />
  }

  if (!groups.length) {
    console.log('Navigating to login due to absense of User Group')
    return <Navigate to="/login" replace />
  }


  const hasAccess = groups.some((group) => allowedGroups.includes(group))

  return hasAccess ? <Outlet /> : <Navigate to="/unauthorized" replace />
}

export default ProtectedRoute
