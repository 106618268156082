import { Button, Container, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import MpFooter from '../../shared/organism/MpFooter'

const Unauthorized = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Stack
        spacing={8}
        sx={{
          alignItems: 'center',
          mt: 10,
          px: 3,
          maxWidth: '600px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
          Oops!
        </Typography>
        <Typography variant="body1">
          You don’t have permission to access this page. Please contact the administrator if you believe this is a mistake.
        </Typography>
        <Button
          component={Link}
          to="/manual-removals"
          variant="contained"
          color={'darkPurple'}
          sx={{
            mt: 2,
            px: 4,
            py: 1,
            textTransform: 'none',
          }}
        >
          Go Home
        </Button>
      </Stack>
      <MpFooter />
    </Container>
  )
}

export default Unauthorized
