import { Container, Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { HomeHeader } from '../../shared/organism/AdminToolHeader'

const UsersManagement = () => {
  return (
    <>
      <HomeHeader />
      <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '12px' }}>
        <Grid container columnSpacing={35} rowSpacing={5}>
          <Grid item xs={12} md={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default UsersManagement
