import dayjs from 'dayjs'
import { formatBrokerNames, formatDayDate } from '../../../utils/helpers'
import {
  IBrokerRecordsActions,
  IBrokerRecordsParams,
} from '../../shared/atom/inputs/brokerRecordTypes'

export const initialState: IBrokerRecordsParams = {
  filters: {
    muid: '',
    executionStatuses: ['optout_in_progress'],
    statuses: ['optout_in_progress'],
    brokerNames: [],
    brokerSources: ['4ScreenMedia', 'rocketreach'],
    startDate: formatDayDate(dayjs().startOf('month')),
    endDate: formatDayDate(dayjs()),
  },
  sort: {
    order: 'desc',
    by: 'update_time',
  },
  page: 1,
  pageSize: 10,
}

const updateFilters = (
  filters: IBrokerRecordsParams['filters'],
  payload: Partial<IBrokerRecordsParams['filters']>,
) => {
  const updatedFilters = { ...filters, ...payload }

  if (
    updatedFilters.executionStatuses?.includes('completed') &&
    !updatedFilters.executionStatuses?.includes('removed')
  ) {
    updatedFilters.executionStatuses =
      updatedFilters.executionStatuses.concat('removed')
  }

  if (payload.brokerNames) {
    updatedFilters.brokerNames = formatBrokerNames(payload.brokerNames)
  }
  if (payload.startDate) {
    updatedFilters.startDate = formatDayDate(dayjs(payload.startDate))
  }
  if (payload.endDate) {
    updatedFilters.endDate = formatDayDate(dayjs(payload.endDate))
  }

  return updatedFilters
}

// Reducer
const brokerRecordsReducer = (
  state: IBrokerRecordsParams = initialState,
  action: IBrokerRecordsActions,
): IBrokerRecordsParams => {
  switch (action.type) {
    case 'UPDATE_FILTERS': {
      return {
        ...state,
        filters: updateFilters(state.filters, action.payload),
        page: 1, // Reset page to 1 when filters are updated
      }
    }
    case 'UPDATE_PAGINATION': {
      const { key, value } = action.payload
      return {
        ...state,
        [key]: value,
        ...(key === 'pageSize' && { page: 1 }), // Reset page to 1 when page size is updated
      }
    }
    case 'UPDATE_SORTERS': {
      const updatedOrder = state.sort.order === 'asc' ? 'desc' : 'asc'
      return {
        ...state,
        sort: {
          order: updatedOrder,
          by: action.payload.property,
        },
        page: 1, // Reset page to 1 when sorters are updated
      }
    }
    default:
      return state
  }
}

export default brokerRecordsReducer
