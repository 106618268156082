import {
    Box,
    ButtonBase,
    Divider,
    Menu,
    MenuItem,
    Typography,
    TableRow,
    TableCell,
    styled,
    tableCellClasses
} from '@mui/material'
import React from 'react'
import MpSvgIcon from './MpSvgIcon'
import { useNavigate } from 'react-router-dom'
import { EmailTemplateData } from '../../../redux/emailTemplateService'
import ConfirmationModal from '../../Views/EmailOutbox/ConfirmationModal'
import { formatScheduledTime } from '../../../utils/helpers'

interface CodeListItemProps {
    template: EmailTemplateData,
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))
const TemplateListItem = ({ template }: CodeListItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const isOpen = Boolean(anchorEl)
    const buttonRef = React.useRef<HTMLButtonElement>(null)

    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const [templateName, setTemplateName] = React.useState('')

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isOpen) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget)
        }
    }

    const close = () => {
        setAnchorEl(null)
        buttonRef.current?.focus()
    }
    const onEdit = async () => {
        navigate('/email-outbox/update/' + template.templateName, {
            replace: true,
            state: {
                emailTemplate: template,
            },
        })
    }
    const onDelete = async (templateName: string) => {
        setOpen(true)
        setTemplateName(templateName)
        setAnchorEl(null)
    }

    return (
        <>
            <StyledTableRow
                sx={{
                    backgroundColor: isOpen ? '#ECF0FD' : 'white',
                    borderRadius: '2px',
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& > *': { borderBottom: 'unset' },
                }}
                key={template.id}>
                <StyledTableCell component="th" scope="row">
                    {template.templateName}
                </StyledTableCell>
                <StyledTableCell >{template.templateType}</StyledTableCell>
                <StyledTableCell >{template.subject}</StyledTableCell>
                <StyledTableCell >
                    {template.fromEmail}
                </StyledTableCell>
                <StyledTableCell >
                    {formatScheduledTime(template.scheduledTime)}
                </StyledTableCell>
                <TableCell>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                        <ButtonBase
                            sx={{ borderRadius: '10px' }}
                            type="button"
                            onClick={handleButtonClick}
                            ref={buttonRef}
                            aria-controls={isOpen ? 'simple-menu' : undefined}
                            aria-expanded={isOpen || undefined}
                            aria-haspopup="menu">
                            <MpSvgIcon icon={isOpen ? 'threeDotsBlue' : 'threeDots'} />
                        </ButtonBase>
                        <Menu
                            open={isOpen}
                            onClose={close}
                            anchorEl={anchorEl}
                            sx={{ borderRadius: '20px' }}>
                            <MenuItem onClick={() => onEdit()}>
                                <Typography variant={'label'}>
                                    Edit
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onDelete(template.templateName)}>
                                <Typography variant={'label'} color={'#ff3D00'}>
                                    Delete
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </TableCell>
                <Divider key={template.id}></Divider>
            </StyledTableRow>
            <ConfirmationModal
                open={open}
                setOpen={setOpen}
                templateName={templateName}
            />
        </>
    )
}

export default TemplateListItem
