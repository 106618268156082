import Box from '@mui/material/Box'
import React, { useContext } from 'react'
import MpSvgIcon, { TwoToneSvg } from '../atom/MpSvgIcon'
import AppBar from '@mui/material/AppBar'
import { NavLink } from 'react-router-dom'
import { AuthContext } from './../../Authentication/AuthContext'

import {
  Container,
  Divider,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import MpTheme from '../../../config/MpTheme'

interface NavigationItemProps {
  destination: string
  sx?: SxProps
  icon: TwoToneSvg
  name: string
}

const NavigationItem = ({
  destination,
  icon,
  sx,
  name,
}: NavigationItemProps) => {
  const onDesktop = {
    display: {
      xs: 'none',
      sm: 'block',
    },
  } as const

  return (
    <NavLink to={destination} style={{ textDecoration: 'none' }}>
      {({ isActive }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...sx,
          }}>
          <MpSvgIcon
            icon={isActive ? `${icon}TwoTone` : icon}
            color={
              isActive
                ? MpTheme.colors.primary.default
                : MpTheme.colors.gray.default
            }
          />
          <Typography
            variant={'caption'}
            sx={{
              color: isActive ? 'primary.main' : 'text.secondary',
              textDecoration: 'none',
              fontWeight: isActive ? '700' : '400',
              ...onDesktop,
            }}>
            {name}
          </Typography>
          <Box
            sx={{
              borderRadius: '50%',
              bgcolor: isActive ? 'primary.main' : 'transparent',
              height: '5px',
              width: '5px',
              mt: 2,
              ...onDesktop,
            }}
          />
        </Box>
      )}
    </NavLink>
  )
}

export const MpHeader = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const context = useContext(AuthContext)
  const { isOwner, isOwnerOrAdmin } = context

  return (
    <Box
      sx={{
        position: 'sticky',
        top: '0px',
        backgroundColor: 'white.main',
        zIndex: 2000,
        marginBottom: '16px',
      }}>
      <Container maxWidth={'xl'} color={'inherit'}>
        <AppBar
          position={'relative'}
          color={'inherit'}
          elevation={0}
          sx={{
            justifySelf: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            mt: 2,
            mx: 'auto',
          }}>
          <NavLink
            to={'/manual-removals'}
            style={{ marginRight: '16px', flexGrow: 1, flexBasis: 0 }}>
            <MpSvgIcon icon={'mePrismPrivacy'} />
          </NavLink>

          <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={4}>
            {
              isOwnerOrAdmin && (
                <NavigationItem
                  destination={'home'}
                  icon={'home'}
                  name={'Corporate Onboarding'}
                />
              )
            }
            <NavigationItem
              destination={'whitepages'}
              icon={'user'}
              name={'White Pages'}
            />
            <NavigationItem
              destination={'manual-removals'}
              icon={'user'}
              name={'Brokers'}
            />
            <NavigationItem
              destination={'settings'}
              icon={'tonedSetting'}
              name={'Settings'}

            />
            {
              isOwnerOrAdmin && (
                <NavigationItem
                  destination={'email-outbox'}
                  icon={'email'}
                  name={'Email outbox'}
                />
              )
            }
            {
              isOwner && (
                <NavigationItem
                  destination={'users-management'}
                  icon={'management'}
                  name={'User Management'}
                />
              )
            }
          </Stack>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              p: 2,
              m: 2,
            }}>
            <NavLink to={'/logout'}>
              <Typography>Logout</Typography>
            </NavLink>
          </Box>
          {!isMobile && (
            <Box sx={{ justifySelf: 'flex-end', ml: 4, flexGrow: 1 }}></Box>
          )}
        </AppBar>
      </Container>
      <Divider sx={{ marginTop: '16px' }} />
    </Box>
  )
}

export default MpHeader
