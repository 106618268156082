import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { API } from 'aws-amplify'
import { IBrokerResults } from './inputs/brokerRecordTypes'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { updateRemovalTableStatus } from '../../../redux/brokerService'

// TODO: This file needs to be revamped in the future for code cleanup and optimization.

interface WpResultProps {
  brokerResults: IBrokerResults
  rowFormat: string
  manualRemovals: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const getFormattedRow = (
  isOpen: boolean,
  brokerResults: IBrokerResults,
  executionStatus: string,
  status: string,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  open: boolean,
  rowFormat: string,
) => {
  const genericStyle = {
    backgroundColor: isOpen ? '#ECF0FD' : 'white',
    borderRadius: '2px',
    '&:last-child td, &:last-child th': { border: 0 },
    '& > *': { borderBottom: 'unset' },
  }

  const genericCollapsableIcon = (
    <TableCell>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
    </TableCell>
  )

  const BrokerLinkDetails = (brokerLink: string) => {
    return brokerLink ? (
      <Link href={brokerLink} target="_blank" rel="noopener noreferrer">
        View Profile
      </Link>
    ) : (
      <span style={{ color: 'gray', cursor: 'not-allowed' }}>View Profile</span>
    )
  }

  const tableRowFormats: Record<string, JSX.Element> = {
    'white-pages': (
      <StyledTableRow sx={genericStyle} key={brokerResults._id}>
        {genericCollapsableIcon}
        <StyledTableCell>{brokerResults.muid}</StyledTableCell>
        <StyledTableCell align="right">{executionStatus}</StyledTableCell>
        <StyledTableCell align="right">
          {BrokerLinkDetails(brokerResults.link)}
        </StyledTableCell>
        <Divider key={brokerResults._id}></Divider>
      </StyledTableRow>
    ),
    'manual-removal': (
      <StyledTableRow sx={genericStyle} key={brokerResults._id}>
        {genericCollapsableIcon}
        <StyledTableCell>{brokerResults.muid}</StyledTableCell>
        <StyledTableCell align="left">
          {brokerResults.data_broker}
        </StyledTableCell>
        <StyledTableCell align="left">{executionStatus}</StyledTableCell>
        <StyledTableCell align="left">{status}</StyledTableCell>
        <StyledTableCell align="left">
          {BrokerLinkDetails(brokerResults.link)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {
            brokerResults?.create_time
              ? new Date(brokerResults.create_time).toISOString().split('T')[0]
              : 'N/A'
          }
        </StyledTableCell>
        <StyledTableCell align="left">
          {
            brokerResults?.update_time
              ? new Date(brokerResults.update_time).toISOString().split('T')[0]
              : 'N/A'
          }
        </StyledTableCell>
        <Divider key={brokerResults._id}></Divider>
      </StyledTableRow>
    ),
  }

  return tableRowFormats[rowFormat]
}

const Row: React.FC<WpResultProps> = React.memo(
  ({
    brokerResults,
    rowFormat,
    manualRemovals,
  }) => {
    const [anchorEl] = useState<HTMLButtonElement | null>(null)
    const isOpen = Boolean(anchorEl)
    const [open, setOpen] = useState(false)
    const [otp, setOtp] = useState<string | null>(null)
    const [status, setStatus] = useState(brokerResults.status || '')
    const [executionStatus, setExecutionStatus] = useState(
      brokerResults.execution_status ||
        (brokerResults.status === 'removed'
          ? 'completed'
          : brokerResults.status) ||
        '',
    )
    const [dialogOpen, setDialogOpen] = useState(false)

    const fetchOtp = useCallback(async () => {
      try {
        const response = await API.post('PlaywrightService', `/wpoptout`, {
          body: { url: brokerResults.link },
          timeout: 120000,
        })

        if (typeof response?.content === 'string') {
          setOtp(response.content)
        } else if (
          typeof response?.content === 'object' &&
          response.content._message
        ) {
          setOtp(response.content._message)
        } else {
          setOtp('')
        }
      } catch (error) {
        setOtp('')
      }
    }, [brokerResults.link])

    const handleUpdateRemovalTableStatus = useCallback(
      async (updatingStatus: string) => {
        const response = await updateRemovalTableStatus(
          brokerResults.scan_result_id,
          updatingStatus,
        )

        if (response?.message === 'Status updated successfully') {
          const { status, execution_status } = response.updated_attributes
          if (status) {
            setStatus('removed')
          }
          if (execution_status) {
            setExecutionStatus('completed')
          }
        }
      },
      [brokerResults.scan_result_id],
    )

    const handleDialogClose = useCallback(
      async (confirmed: boolean) => {
        if (confirmed) {
          try {
            const response = await API.put(
              'CorpOnboarding',
              `/broker/update/${brokerResults.scan_result_id}?muid=${brokerResults.muid}&status=removed`,
              {},
            )

            setStatus(response.status)
          } catch (error) {
            console.error('Error updating status:', error)
          }
        }
        setDialogOpen(false)
      },
      [brokerResults.muid, brokerResults.scan_result_id],
    )

    return (
      <>
        {getFormattedRow(
          isOpen,
          brokerResults,
          executionStatus,
          status,
          setOpen,
          open,
          rowFormat,
        )}

        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
          align={'center'}>
          {brokerResults.detail && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  width: '100%', // Ensure the Stack takes full width
                  justifyContent: 'space-between', // Distribute Cards evenly
                }}>
                <Typography
                  variant={'h3'}
                  sx={{
                    color: '#3A155A',
                    fontWeight: 700,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minWidth: '10%', // Adjust this to keep proper space between items
                  }}>
                  Details
                </Typography>

                <Card
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 10, whiteSpace: 'nowrap' }}
                      color="text.secondary">
                      {`${brokerResults.detail.first_name} ${brokerResults.detail.middle_name} ${brokerResults.detail.last_name}`}
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 10 }} color="text.secondary">
                      {`${brokerResults.detail.addresses[0].city} ${brokerResults.detail.addresses[0].state}`}
                    </Typography>
                  </CardContent>
                </Card>

                <Card sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 10, textAlign: 'center' }}
                      color="text.secondary">
                      {otp ? ` ${otp}` : 'No OTP'}
                    </Typography>
                    <CardActions
                      sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      {rowFormat !== 'manual-removal' ? (
                        <Button
                          size="small"
                          onClick={fetchOtp}
                          disabled={
                            status === 'removed' ||
                            executionStatus === 'completed'
                          }
                          variant="outlined">
                          Opt-out
                        </Button>
                      ) : (
                        <Link href={brokerResults.link} target="_blank">
                          <Button
                            size="small"
                            disabled={
                              status === 'removed' ||
                              executionStatus === 'completed'
                            }
                            variant="outlined">
                            Opt-out
                          </Button>
                        </Link>
                      )}
                      <Button
                        size="small"
                        onClick={() =>
                          handleUpdateRemovalTableStatus('execution_status')
                        }
                        disabled={
                          status === 'removed' ||
                          executionStatus === 'completed'
                        }
                        variant={
                          executionStatus === 'completed'
                            ? 'outlined'
                            : 'contained'
                        }
                        color="success">
                        Executed
                      </Button>
                    </CardActions>
                  </CardContent>
                </Card>

                <Card sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        padding: 2,
                        border: '1px solid',
                        borderRadius: 1,
                        backgroundColor: 'background.paper',
                      }}>
                      Status: {brokerResults.detail?.status}
                    </Typography>
                    <CardActions
                      sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      <Button
                        size="small"
                        onClick={() => handleUpdateRemovalTableStatus('status')}
                        variant="outlined">
                        Removed
                      </Button>
                      <Dialog
                        open={dialogOpen}
                        onClose={() => handleDialogClose(false)}>
                        <DialogTitle>
                          {'Updating status without executing?'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            You have not processed this profile URL for removal.
                            Do you still want to update the removal status?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => handleDialogClose(false)}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => handleDialogClose(true)}
                            autoFocus>
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </CardActions>
                  </CardContent>
                </Card>
              </Stack>
            </Collapse>
          )}
        </TableCell>
      </>
    )
  },
)

export default Row
