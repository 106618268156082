import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import {
  confirmSignUp,
  forgotPassword,
  handleAuthChallenge,
  resendSignup,
  submitForgotPassword,
} from '../../services/AuthenticationService'
import { AnalyticsService } from '../../services/AnalyticsService'
import OtpInput from 'react-otp-input'
import { AuthLoader } from '../gateways/AuthLoader'
import { signInWithEmail } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { useAppDispatch } from '../../redux/storeExports'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Hub } from 'aws-amplify'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import { AuthContext } from './AuthContext'
import { Logger } from '@meprism/app-utils'
import { Toast } from '../toast/toast'
import MpTheme from '../../config/MpTheme'
import MpReTheme, {
  MpReColorPalette,
  fonts,
  theme,
} from '../../config/SigninTheme'
import SignInSideLeft from '../shared/organism/SignInLeftLayout'

const ENTER_OPERATION_KEY = 'Enter'

export type OtpRouteParam =
  | EmailSignUpOtp
  | ChangePasswordOtp
  | SignInSms
  | SignInTotp
  | VerifyAttribute

export type EmailSignUpOtp = {
  actionType: 'SIGNUP_EMAIL'
  username: string
  from?: string
  destination: string
}

export type ChangePasswordOtp = {
  actionType: 'CHANGE_PASSWORD'
  username: string
  password: string
  from?: string
  destination: string
}

export type SignInSms = {
  actionType: 'SIGNIN_SMS_MFA'
  from?: string
  destination: string
}

export type SignInTotp = {
  actionType: 'SIGNIN_SOFTWARE_TOKEN_MFA'
  from?: string
  destination: string
}

export type VerifyAttribute = {
  actionType: 'VERIFY_ATTRIBUTE'
  attribute: 'email' | 'phone_number'
  from?: string
  destination: string
}

// easy to get these navigations wrong, let's use a typed helper
export const handleOtpNavigation = (
  navigate: NavigateFunction,
  state: OtpRouteParam,
) => {
  navigate('/otp', { replace: state.actionType !== 'VERIFY_ATTRIBUTE', state })
}

export const LoginOTP = () => {
  const location = useLocation()
  const state = location.state as OtpRouteParam
  const [otp, setOtp] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)

  useEffect(
    () =>
      Hub.listen('auth', (capsule) => {
        const { payload } = capsule
        if (payload.event === 'autoSignIn') {
          Logger.debug('Attempting autoSignIn from LoginOTP')
          const user: CognitoUser = payload.data
          if (user.challengeName) {
            Logger.debug(`Got auth challenge ${user.challengeName}, handling`)
            handleAuthChallenge({
              user,
              setUser: authContext.setUser,
              location,
              navigate,
            })
          } else {
            navigate(state.from || '/', { replace: true })
          }
        }
      }),
    [location, navigate, authContext.setUser, state.from],
  )

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ENTER_OPERATION_KEY) {
      onVerify()
    }
  }

  const getVerificationAction = (): (() => void) => {
    switch (state.actionType) {
      case 'SIGNUP_EMAIL':
        return async () => {
          try {
            const user = await confirmSignUp(state.username, otp)
            Logger.debug(`Got ${user} from confirmSignUp`)
          } catch (error) {
            if (error instanceof Error) {
              switch (error.name) {
                case 'CodeMismatchException':
                  setErrorText(
                    'The code you have entered is incorrect. Please try again',
                  )
                  break
                case 'ExpiredCodeException':
                  await resendSignup(state.username)
                  setErrorText(
                    `The code you entered has expired. We have sent a new one to ${state.username}`,
                  )
                  break
                default:
                  setErrorText(
                    'An error occurred processing your request. Please try again later.',
                  )
                  AnalyticsService.error(
                    `Unhandled Authorization error: `,
                    error,
                  )
                  break
              }
            } else {
              AnalyticsService.error(`Unhandled Authorization error: `, error)
            }
          }
        }
      case 'CHANGE_PASSWORD':
        return async () => {
          try {
            await submitForgotPassword(
              state.username,
              otp as string,
              state.password,
            )
            await dispatch(signInWithEmail({ ...state })).unwrap()
            navigate('/changePasswordSuccess', { state: state.from })
          } catch (error) {
            if (error instanceof Error) {
              switch (error.name) {
                case 'CodeMismatchException':
                  setErrorText(
                    'The code you have entered is incorrect. Please try again',
                  )
                  break
                case 'ExpiredCodeException':
                  await forgotPassword(state.username)
                  setErrorText(
                    `The code you entered has expired. We have sent a new one to ${state.username}`,
                  )
                  break
                case 'LimitExceededException':
                  setErrorText(
                    `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
                  )
                  AnalyticsService.error('CognitoLimit', error)
                  break
                default:
                  setErrorText(
                    'An error occurred processing your request. Please try again later.',
                  )
                  AnalyticsService.error(
                    `Unhandled Authorization error: `,
                    error,
                  )
                  break
              }
            } else {
              AnalyticsService.error(`Unhandled Authorization error: `, error)
            }
          }
        }
      case 'SIGNIN_SMS_MFA':
        return async () => {
          try {
            await Auth.confirmSignIn(authContext.user, otp, 'SMS_MFA')
            navigate(state.from || '/', { replace: true })
          } catch (error) {
            switch (error?.name) {
              case 'CodeMismatchException':
                setErrorText(
                  'The code you have entered is incorrect. Please try again',
                )
                break
              case 'ExpiredCodeException':
                navigate('/login', {
                  replace: true,
                  state: { from: state.from },
                })
                Toast.show({
                  type: 'error',
                  text1: 'Your code has expired. You must log in again',
                })
                break
              case 'LimitExceededException':
                setErrorText(
                  `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
                )
                AnalyticsService.error('CognitoLimit', error)
                break
              default:
                setErrorText(
                  'An error occurred processing your request. Please try again later.',
                )
                AnalyticsService.error(`Unhandled Authorization error: `, error)
                break
            }
          }
        }
      case 'VERIFY_ATTRIBUTE':
        return async () => {
          try {
            await Auth.verifyCurrentUserAttributeSubmit(state.attribute, otp)
            navigate(state.from || '/', { replace: true })
          } catch (error) {
            switch (error?.name) {
              case 'CodeMismatchException':
                setErrorText(
                  'The code you have entered is incorrect. Please try again',
                )
                break
              case 'ExpiredCodeException':
                navigate(-1)
                Toast.show({
                  type: 'error',
                  text1: 'Your code has expired. You must log in again',
                })
                break
              case 'LimitExceededException':
                setErrorText(
                  `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
                )
                AnalyticsService.error('CognitoLimit', error)
                break
              default:
                setErrorText(
                  'An error occurred processing your request. Please try again later.',
                )
                AnalyticsService.error(`Unhandled Authorization error: `, error)
                break
            }
          }
        }

      case 'SIGNIN_SOFTWARE_TOKEN_MFA':
        return async () => {
          try {
            await Auth.confirmSignIn(
              authContext.user,
              otp,
              'SOFTWARE_TOKEN_MFA',
            )
            navigate(state.from || '/manual-removals', { replace: true })
          } catch (error) {
            switch (error?.name) {
              case 'CodeMismatchException':
                setErrorText(
                  'The code you have entered is incorrect. Please try again',
                )
                break
              case 'ExpiredCodeException':
                navigate('/login', {
                  replace: true,
                  state: { from: state.from },
                })
                Toast.show({
                  type: 'error',
                  text1: 'Your code has expired. You must log in again',
                })
                break
              case 'LimitExceededException':
                setErrorText(
                  `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
                )
                AnalyticsService.error('CognitoLimit', error)
                break
              default:
                setErrorText(
                  'An error occurred processing your request. Please try again later.',
                )
                AnalyticsService.error(`Unhandled Authorization error: `, error)
                break
            }
          }
        }
    }
  }

  // unfortunately, resend is not available in the vast majority of circumstances
  // yea yea amplify / cognito is the worst
  const getResendAction = () => {
    console.log('ccc')
    switch (state.actionType) {
      case 'SIGNUP_EMAIL':
        return async () => Auth.resendSignUp(state.username)
      case 'CHANGE_PASSWORD':
        return async () => Auth.forgotPassword(state.username)
      default:
        return undefined
    }
  }

  const onVerify = getVerificationAction()
  const onResend = getResendAction()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{ height: '100vh', position: 'relative' }}>
        {!isMobile && <SignInSideLeft />}

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          square
          sx={{
            backgroundColor: MpReColorPalette.background.default,
          }}>
          <Box>
            <Box
              sx={{
                my: 8,
                p: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                maxWidth: MpReTheme.layouts.widths.sm,
              }}>
              {isMobile ? (
                <Tabs
                  value={1}
                  sx={{
                    '.MuiTab-root': { alignItems: 'start' },
                  }}
                  indicatorColor="secondary"
                  textColor="primary"
                  centered={false}>
                  <Tab
                    label="1."
                    style={{ width: '20%' }}
                    sx={{ margin: '0 5px', flexGrow: 0, textTransform: 'none' }}
                  />
                  <Tab
                    label="2.Verify"
                    style={{ width: '40%' }}
                    sx={{
                      margin: '0 5px',
                      flexGrow: 1,
                      color: 'inherit',
                      '&.Mui-selected': { color: 'secondary.main' },
                    }}
                  />
                  <Tab
                    label="3."
                    style={{ width: '20%' }}
                    sx={{ margin: '0 5px', flexGrow: 0 }}
                  />
                </Tabs>
              ) : (
                <Tabs
                  value={1} // Set the default active tab index
                  indicatorColor="secondary"
                  textColor="primary"
                  centered={false}>
                  <Tab
                    label="1. Sign Up"
                    sx={{ margin: '0 5px', alignItems: 'start', flexGrow: 1 }}
                  />
                  <Tab
                    label="2. Verify"
                    sx={{
                      margin: '0 5px',
                      textTransform: 'none',
                      alignItems: 'start',
                      flexGrow: 1,
                      color: 'inherit',
                      '&.Mui-selected': { color: 'secondary.main' },
                    }}
                  />
                  {/* <Tab
                    label="3. Setup Profile"
                    sx={{ margin: '0 5px', flexGrow: 1 }}
                  /> */}
                </Tabs>
              )}

              <Container
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  [theme.breakpoints.down('sm')]: {
                    my: 4,
                  },
                  [theme.breakpoints.up('md')]: {
                    my: 30,
                  },
                }}>
                <Stack
                  spacing={10}
                  maxWidth={MpTheme.layouts.widths.md}
                  sx={{ mx: 'auto', alignItems: 'left', my: 10 }}>
                  <AuthLoader>
                    <Typography variant={'h3'}>
                      Enter Account Verification Code
                    </Typography>
                    <Typography variant="body5" sx={{ textAlign: 'left' }}>
                      {`An account verification code has been sent to `}
                      <Typography
                        variant={'body1'}
                        sx={{ fontWeight: 'bold' }}
                        display={'inline'}>
                        {state.destination}
                      </Typography>
                    </Typography>
                    <Box
                      sx={{
                        maxWidth: MpTheme.layouts.widths.sm,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        inputStyle={{
                          width: isMobile ? '16.666%' : '25%',
                          height: isMobile ? '42px' : '60px',
                          border: '2px solid #EFEDFDB2',
                          borderRadius: '10px',
                          background: '#1A1B20',
                          color: '#ffffff',
                          padding: '2px',
                        }}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} onKeyDown={handleKeyDown} />}
                      />
                    </Box>
                    <Box
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          my: 2,
                        },
                        [theme.breakpoints.up('md')]: {
                          my: 8,
                        },
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontFamily: fonts.Inter.Medium,
                      }}>
                      {onResend && (
                        <Link onClick={onResend} sx={{ cursor: 'pointer' }}>
                          Resend Code
                        </Link>
                      )}
                    </Box>
                    <Box>
                      <Typography color={'error'}>{errorText}</Typography>
                    </Box>
                    <Button
                      variant={'contained'}
                      onClick={onVerify}
                      disabled={otp === ''}
                      color={'darkPurple'}>
                      Verify Code
                    </Button>
                  </AuthLoader>
                </Stack>
              </Container>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'left',

                justifyContent: 'left',
                flexDirection: 'column',
                mx: 9,
                mb: 10,
              }}>
              <Stack direction="row" spacing={10}>
                <Link
                  variant="body2"
                  href="https://meprism.com/terms-conditions"
                  sx={{ color: '#9135E0' }}>
                  Terms & Conditions
                </Link>
                <Link
                  variant="body2"
                  href="https://meprism.com/privacy-policy"
                  sx={{ color: '#9135E0' }}>
                  Privacy Policy
                </Link>
                <Link
                  variant="body2"
                  href="https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17"
                  sx={{ color: '#9135E0' }}>
                  Contact Us
                </Link>
              </Stack>
            </Box>
          </Box>
        </Grid>
        {isMobile && <SignInSideLeft />}
      </Grid>
    </ThemeProvider>
  )
}

export default LoginOTP
