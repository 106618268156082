import {
    Skeleton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableBody,
} from '@mui/material'
import React, { Suspense, useEffect } from 'react'
import { useAppDispatch } from '../../../redux/storeExports'
import { Await, useRouteLoaderData } from 'react-router-dom'
import { EmailTemplateSelector, fetchAllTemplates } from '../../../redux/emailTemplateService'
import TemplateListItem from '../../shared/atom/EmailTemplateItem'
import CenteredTabs from './Tabs'


interface EmailListProps {
    removed?: boolean
}

const EmailList = (props: EmailListProps) => {
    const dispatch = useAppDispatch()
    const [value, setValue] = React.useState('standard');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchAllTemplates())
    }, [dispatch])
    const templates = EmailTemplateSelector(value)


    return (
        <>
            <CenteredTabs value={value} handleChange={handleChange} />
            <TableContainer
                component={Paper}
                sx={{ minWidth: 850 }}
                aria-label="simple table">
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Template name</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Subject</TableCell>
                            <TableCell align="left">From Email</TableCell>
                            <TableCell align="left">Schedule Time</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates.map((template, index) => (
                            <TemplateListItem template={template} key={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const EmailItemSkeleton = () => {
    return <Skeleton />
}

const EmailListSkeleton = () => {
    return (
        <>
            {[0, 0, 0, 0, 0].map((_, index) => (
                <EmailItemSkeleton key={index} />
            ))}
        </>
    )
}

const EmailTemplateListSuspense = (props: EmailListProps) => {
    const loaderData = useRouteLoaderData('main') as any
    return (
        <Suspense fallback={<EmailListSkeleton />}>
            <Await resolve={loaderData.users} errorElement={<EmailList {...props} />}>
                <EmailList {...props} />
            </Await>
        </Suspense>
    )
}

export default EmailTemplateListSuspense
