import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Logger } from '@meprism/app-utils'
import { Toast } from '../components/toast/toast'
import { RootState } from './store'
import { useSelector } from 'react-redux'

export type ScheduledTime = {
  type: string;
  day: number;
  month: string;
  year: number | null;
};
export type EmailTemplateDataReturn = {
  _id?: string
  template_name: string
  template_type: string
  subject: string
  body: string | undefined
  email_to: string
  email_from: string
  scheduled_time: ScheduledTime,
  email_cc: string[],
  email_bcc: string[],
  active_status: boolean
}
export type EmailTemplateData = {
  id?: string
  templateName: string
  templateType: string
  subject: string
  body?: string | undefined
  toEmail: string
  fromEmail: string
  scheduledTime: ScheduledTime
  ccEmails: string[],
  bccEmails: string[]
  status: boolean,
  sendTestEmail?: boolean
}

export interface EmailTemplateState {
  emailTemplates: EmailTemplateData[]
}

export const initialEmailTemplateState: EmailTemplateState = {
  emailTemplates: [] as EmailTemplateData[],
}
type CreateEmailTemplateData = {
  fields: EmailTemplateDataReturn,
  send_test_email?: boolean
}

const buildTemplateFromBackendReturn = (emailTemplates: EmailTemplateDataReturn[]): EmailTemplateData[] => {
  return emailTemplates.map((template: EmailTemplateDataReturn) => ({
    id: template._id,
    templateName: template.template_name,
    templateType: template.template_type,
    subject: template.subject,
    body: template.body,
    toEmail: template.email_to,
    fromEmail: template.email_from,
    scheduledTime: template.scheduled_time,
    ccEmails: template.email_cc,
    bccEmails: template.email_bcc,
    status: template.active_status
  }))
}

const createEmailTemplateApi = async (
  data: CreateEmailTemplateData,
): Promise<any> => {
  return API.post('CorpOnboarding', '/email_template/create', { body: data })
}

const updateEmailTemplateApi = (
  data: CreateEmailTemplateData,
): Promise<CreateEmailTemplateData> => {
  return API.put('CorpOnboarding', `/email_template/update`, { body: data })
}

const getEmailTemplatesApi = async (): Promise<any> => {
  return API.post('CorpOnboarding', '/email_templates', {
    body: {}
  })
}

const deleteTemplate = async (templateName: string) => {
  return API.del('CorpOnboarding', `/email_template/delete`, {
    body: {
      template_name: templateName
    }
  })
}


export const createEmailTemplate = createAsyncThunk(
  'createEmailTemplate',
  async (data: EmailTemplateData) => {
    try {
      const dataToSend = {
        fields: {
          template_name: data.templateName,
          template_type: data.templateType,
          subject: data.subject,
          body: data.body,
          email_to: data.toEmail,
          email_from: data.fromEmail,
          email_cc: data.ccEmails,
          email_bcc: data.bccEmails,
          scheduled_time: data.scheduledTime,
          active_status: data.status
        },
        send_test_email: data.sendTestEmail
      }
      const response = await createEmailTemplateApi(dataToSend)
      const toastMessage = data.sendTestEmail ? 'You will receive test email shortly' : 'Email template has been created'
      Toast.show({ type: 'success', text1: toastMessage })
      return response
    } catch (error) {
      Logger.error(`Error creating email template: ${error}`)
      const errorMessage =
        typeof error.response.data.detail === 'string'
          ? error.response.data.detail
          : 'Email template cannot be created'
      Toast.show({
        type: 'error',
        text1: errorMessage,
      })
      throw error
    }
  },
)

export const updateEmailTemplate = createAsyncThunk(
  'updateEmailTemplate',
  async (data: EmailTemplateData) => {
    try {
      const dataToSend = {
        fields: {
          template_name: data.templateName,
          template_type: data.templateType,
          subject: data.subject,
          body: data.body,
          email_to: data.toEmail,
          email_from: data.fromEmail,
          email_cc: data.ccEmails,
          email_bcc: data.bccEmails,
          scheduled_time: data.scheduledTime,
          active_status: data.status
        },
        send_test_email: data.sendTestEmail
      }
      const response = await updateEmailTemplateApi(dataToSend)
      const toastMessage = data.sendTestEmail ? 'You will receive test email shortly' : 'Email template has been updated'
      Toast.show({ type: 'success', text1: toastMessage })
      return response
    } catch (error) {
      Logger.error(`Error updating email template: ${error}`)
      const errorMessage =
        typeof error.response.data.detail === 'string'
          ? error.response.data.detail
          : 'Email template cannot be created'
      Toast.show({
        type: 'error',
        text1: errorMessage,
      })
      throw error
    }
  },
)

export const fetchAllTemplates = createAsyncThunk('fetchAllTemplates', async () => {
  try {
    const response = await getEmailTemplatesApi()

    return buildTemplateFromBackendReturn(response.email_templates)

  } catch (error) {
    Logger.error(`Error fetching email templates: ${error}`)
    Toast.show({
      type: 'error',
      text1: 'Error fetching email templates',
    })
    throw error
  }
})

export const removeTemplate = createAsyncThunk(
  'removeTemplate',
  async (payload: string) => {
    try {
      return await deleteTemplate(payload)
    } catch (error) {
      Logger.error(`Error deleting template ${payload} : ${error}`)
      Toast.show({
        type: 'error',
        text1: 'Error deleting template',
      })
      throw error
    }
  },
)

const { actions, reducer } = createSlice({
  name: 'emailTemplateService',
  initialState: initialEmailTemplateState,
  reducers: {
    populateEmailTemplates: (
      state,
      { payload }: { payload: EmailTemplateData[] },
    ) => {
      state.emailTemplates = payload
    },
  },
  extraReducers: (builder) => {
    // Add extra reducers if needed
    builder.addCase(fetchAllTemplates.fulfilled, (state, { payload }) => {
      state.emailTemplates = payload
    })
  },
})

export const EmailTemplateServiceActions = actions
export const corporateEmailTemplateServiceReducer = reducer

export const EmailTemplateServiceSelector = () => (state: RootState) =>
  state.corporateEmailTemplateService

export const EmailTemplateSelector = (templateType: string) => {
  let templates = useSelector((state: RootState) => state.corporateEmailTemplateService.emailTemplates);
  switch (templateType) {
    case 'standard':
      return templates.filter(template => template.templateType === 'monthly_stats_email');
    case 'customized':
      return templates.filter(template => template.templateType === 'generic_email');
    default:
      return templates;
  }
}
