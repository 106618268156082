import React, { createContext, PropsWithChildren, useState, useEffect, useCallback, useMemo } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'

export type AuthContextType = {
  user: CognitoUser | null | undefined
  groups: string[]
  isOwner: boolean
  isOwnerOrAdmin: boolean
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null | undefined>>
  loading: boolean
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  groups: [],
  isOwner: false,
  isOwnerOrAdmin: false,
  setUser: () => {},
  loading: true,
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<CognitoUser | null>()
  const [groups, setGroups] = useState<string[]>([])

  const fetchUserAndGroups = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      const userGroups = currentUser.signInUserSession.idToken.payload['cognito:groups'] || []
      setUser(currentUser)
      setGroups(userGroups)
    } catch (error) {
      console.error('Error fetching authenticated user or groups:', error)
      setUser(null)
      setGroups([])
    }
  }, [])

  useEffect(() => {
    fetchUserAndGroups()

    const listener = ({ payload: { event } }: any) => {
      if (event === 'signIn') {
        fetchUserAndGroups()
      } else if (event === 'signOut') {
        setUser(null)
        setGroups([])
      }
    }

    Hub.listen('auth', listener)
    return () => {
      Hub.remove('auth', listener)
    }
  }, [fetchUserAndGroups])

  const loading = user === undefined || groups.length === 0

  const { isOwner, isOwnerOrAdmin } = useMemo(() => {
    const owner = groups.includes('Owner')
    const ownerOrAdmin = owner || groups.includes('Admin')
    return { isOwner: owner, isOwnerOrAdmin: ownerOrAdmin }
  }, [groups])

  return (
    <AuthContext.Provider value={{ user, groups, isOwner, isOwnerOrAdmin, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  )
}
