import React from "react"
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import closeIcon from '@meprism/shared/src/assets/icons/successclose.png'


interface ConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string
  description?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title = "Are you sure?",
  description = "Do you want to proceed with this action?",
}) => {
  const buttonStyles = {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "unset",
  }

  return (
    <Modal
      open={open}
      sx={{
        backdropFilter: 'blur(5px)',
        background: 'rgba(0, 0, 0, 0.01)',
      }}
      onClose={onClose}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxWidth: '600px',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          background: '#fff',
          p: 10,
          borderRadius: '10px',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{
              position: 'absolute',
              top: -60,
              right: 10,
              zIndex: 1,
              color: '#ffffff',
          }}
        >
          <img alt="close" src={closeIcon} style={{ width: '42px' }} />
        </IconButton>
        <Stack spacing={2} flexDirection={'column'}>
          <Typography variant="h2" mb={2} textAlign="center">
              {title}
          </Typography>
          <Typography variant="body2" mb={2} textAlign="center" color={'#615F6B'}>
              {description}
          </Typography>
        </Stack>
        <Grid container spacing={2} mt={5}>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'contained'}
              color={'darkPurple'}
              onClick={onClose}
              sx={{
                ...buttonStyles,
                border: '2px solid #C67EFF',
                background: 'transparent',
                color: '#C67EFF',
                '&.Mui-disabled': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
                ':hover': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'outlined'}
              color={'darkPurple'}
              onClick={() => onConfirm()}
              sx={{
                ...buttonStyles,
                p: 2,
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ConfirmationModal
