import { z } from "zod";


export const validMonths = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
];

const isEndOfMonth = (val: string) => validMonths.includes(val) || val === "end_of_month";

export const createEmailTemplateSchema = () => {
    const emailTemplateSchema = z.object({
        templateName: z
            .string()
            .nonempty('Template name is required')
            .min(2, 'Template name must be at least 2 characters')
            .max(50, 'Name cannot be more than 50 characters')
        ,
        templateType: z.string().nonempty('Type is required'),
        toEmail: z.union([
            z.literal('all_users'),
            z.literal('all_free_users'),
            z.literal('all_subscribed_users'),
            z.string().nonempty('To email is required').email('Invalid email format').max(100, 'Email cannot be longer than 100 characters'),
        ]),
        fromEmail: z.union([
            z.literal('support@meprism.com'),
            z.literal('takeaction@meprism.com'),
            z.literal('no-reply@meprism.com'),
            z.string().nonempty('To email is required').email('Invalid email format').max(100, 'Email cannot be longer than 100 characters'),
        ]),
        ccEmails: z
            .array(z.string().email('Invalid email format'))
            .optional()
            .default([]),
        bccEmails: z.array(z.string().email('Invalid email format')).optional().default([]),
        body: z.string().default(''),
        subject: z
            .string({
                required_error: "Subject is required",
            })
            .min(5, "Subject must be at least 5 characters")
            .max(200, "Subject cannot be longer than 200 characters"),
        scheduledTime: z.union([
            z.coerce
                .date()
                .min(new Date(), "Schedule date must be in the future")
                .refine((val) => val !== null, {
                    message: "Please select a valid schedule date",
                }),
            z.string().refine(isEndOfMonth, {
                message: "Invalid value for schedule time",
            }),
        ]),
        scheduledMonth: z
            .date()
            .optional()
            .refine(
                (val) => {
                    if (val === undefined) return true;
                    const isLastDay = new Date(val.getFullYear(), val.getMonth() + 1, 0).getDate() === val.getDate();
                    return isLastDay;
                },
                {
                    message: "Scheduled month must be the last day of the selected month",
                }
            ),
        status: z.boolean().default(false),

    }).refine(
        (data) => {
            // Ensure `scheduledMonth` is required if `scheduledTime` is "end_of_month"
            if (data.scheduledTime === "end_of_month") {
                return data.scheduledMonth !== undefined;
            }
            return true;
        },
        {
            message: "Scheduled month is required when scheduled time is 'end of month'",
            path: ["scheduledMonth"], // Associate error with `scheduledMonth` field
        }
    );

    return emailTemplateSchema;
}





const emailTemplateSchema = createEmailTemplateSchema()
export const asEmailTemplate = (
    schema: z.TypeOf<typeof emailTemplateSchema>,
): EmailTemplateFields => ({
    templateName: schema.templateName,
    toEmail: schema.toEmail || '',
    fromEmail: schema.fromEmail,
    subject: schema.subject,
    body: schema.body,
    ccEmails: (schema.ccEmails || [])
        .filter(Boolean)
        .map((email) => ({ email, validated: false })),
    bccEmails: (schema.bccEmails || [])
        .filter(Boolean)
        .map((email) => ({ email, validated: false })),
    scheduledTime: schema.scheduledTime
        ? schema.scheduledTime.toString()
        : null,
    templateType: schema.templateType
});

// Define the EmailTemplateFields type
interface EmailTemplateFields {
    templateName: string;
    toEmail: string;
    fromEmail: string | 'info@mePrism.com' | 'support@mePrism.com' | 'hr@mePrism.com';
    subject: string;
    body: string;
    ccEmails: { email: string; validated: boolean }[];
    bccEmails: { email: string; validated: boolean }[];
    scheduledTime: string | null;
    templateType: string;
}
