import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import React from 'react'
import MpTheme from '../../../config/MpTheme'

interface SettingsSubheaderProps {
  pathName:
  | 'profile'
  | 'account'
  | 'create'
  | 'manage'
  | 'update'
  | 'whitepages'
  | 'manual-removals'
  | 'email-outbox'
  | 'users-management'
}

const subheaders = {
  profile: {
    title: 'Billing Information',
    subtitle: ' ',
  },
  account: {
    title: 'Account',
    subtitle: 'Your mePrism account access and password management',
  },
  create: {
    title: 'Create Code',
    subtitle: 'Create Corporate Code for the Business admin',
  },
  manage: {
    title: 'Manage Codes',
    subtitle: 'Manage Corporate Codes ',
  },

  update: {
    title: 'Update Codes',
    subtitle: 'update Corporate Codes ',
  },
  whitepages: {
    title: 'Whitepages  Tool',
    subtitle: 'Admin Tool For Whitepages removals  ',
  },
  'manual-removals': {
    title: 'Manual Removal  Tool',
    subtitle: 'Admin Tool For Manual removals  ',
  },
  'email-outbox': {
    title: 'Email Outbox',
    subtitle: '',
  },
  'users-management': {
    title: 'Users Management',
    subtitle: 'Manage Users For Admin Tool ',
  },
} as const

export const SettingsSubheader = ({ pathName }: SettingsSubheaderProps) => {
  const { title, subtitle } = subheaders[pathName]

  return (
    <Box
      sx={{
        maxWidth: MpTheme.layouts.widths.md,
        mx: 'auto',
      }}>
      <Typography
        variant={'h1'}
        sx={{ color: '#3A155A', fontWeight: 700, textAlign: 'center' }}>
        {title}
      </Typography>
      <Typography
        variant={'body2'}
        sx={{ textAlign: 'center', whiteSpace: 'break-spaces' }}>
        {subtitle}
      </Typography>
      <Divider sx={{ mt: 4, mb: 10 }} />
    </Box>
  )
}
