import AppBar from '@mui/material/AppBar'
import React from 'react'
import Stack from '@mui/material/Stack'
import { NavLink, useMatches } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { SettingsSubheader } from './SettingsSubheader'
import { Button } from '@mui/material'

interface SettingsNavigationItemProps {
  destination: string
  label: string
}

interface HomeHeaderProps {
  allBrokers?: Boolean
}

type PathName =
  | 'profile'
  | 'account'
  | 'create'
  | 'manage'
  | 'update'
  | 'whitepages'
  | 'manual-removals'
  | 'email-outbox'
  | 'users-management'

const pathDetails: Record<string, Record<string, string>> = {
  'users-management': {
    manage: 'Manage Users',
    create: 'Create User',
  },
  whitepages: {
    results: 'Whitepages',
  },
  'email-outbox': {
    create: 'Create New',
  },
}

const getPathComponent = (path: string): PathName => {
  switch (path) {
    case 'whitepages':
    case 'manage':
    case 'update':
      return path
    default:
      return 'whitepages'
  }
}

const routesMap: Record<PathName, PathName> = {
  'manual-removals': 'manual-removals',
  'email-outbox': 'email-outbox',
  'users-management': 'users-management',
  profile: 'profile',
  account: 'account',
  create: 'create',
  manage: 'manage',
  update: 'update',
  whitepages: 'whitepages',
}

const myPaths = (routeComponents: string[]): PathName => {
  const key = routeComponents[1] as PathName
  return routesMap[key] || getPathComponent(routeComponents[2] || '')
}

const SettingsNavigationItem = ({
  destination,
  label,
}: SettingsNavigationItemProps) => {
  return (
    <NavLink to={destination}>
      {({ isActive }) => (
        <Button variant={isActive ? 'contained' : 'outlined'}>{label}</Button>
      )}
    </NavLink>
  )
}

export const HomeHeader = ({ allBrokers }: HomeHeaderProps) => {
  // const profileName = useAppSelector(ProfileSelectors.selectProfileName)
  // @TODO: Replace this
  const matches = useMatches()
  const routeComponents = matches[matches.length - 1].pathname
    .replace(/\/$/, '')
    .split('/')
  const currentPaths = pathDetails[routeComponents[1]]

  return (
    <AppBar
      position={'relative'}
      color={'transparent'}
      elevation={0}
      sx={{
        maxWidth: 'xl',
        justifySelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        mx: 'auto',
        px: 5,
        mt: '28px',
      }}
    >
      <Grid container rowSpacing={10}>
        {Boolean(routeComponents[2]) && (
          <Grid
            container
            item
            xs={12}
            sm={2}
            sx={{ justifyContent: 'center', alignItems: 'center', minWidth: 500 }}
          >
            <Stack direction={'row'} spacing={3}>
              {currentPaths &&
                Object.entries(currentPaths).map(([key, value]) => (
                  <SettingsNavigationItem key={key} destination={key} label={value} />
                ))}
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <SettingsSubheader pathName={myPaths(routeComponents)} />
        </Grid>
      </Grid>
    </AppBar>
  )
}
