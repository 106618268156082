import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface CenteredTabsProps {
    value: string;
    handleChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export default function CenteredTabs({ value, handleChange }: CenteredTabsProps) {

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: 4 }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Standard" value={'standard'} />
                <Tab label="Customized" value={'customized'} />
            </Tabs>
        </Box>
    );
}
